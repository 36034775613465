import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Divider, ImageList, ImageListItem, Typography } from "@mui/material";
// import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import axios from "axios";
import FaxIcon from "@mui/icons-material/Fax";
import LanguageIcon from "@mui/icons-material/Language";
import EmailIcon from "@mui/icons-material/Email";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function BasicGrid() {
  const [data1, setData1] = React.useState([]);
  const [data2, setData2] = React.useState(null);

  React.useEffect(() => {
    Promise.all([
      axios.get(
        "https://esystems.cdl.lk/backend-Test/eSupplier/Supplier/GetInquiryDetails?inquiryId=BS-5001-A03-0742"
      ),
      axios.get(
        "https://esystems.cdl.lk/backend-Test/eSupplier/Supplier/GetSupplierdetailsBySupplier?supCode=M0206"
      ),
    ])
      .then(([response1, response2]) => {
        setData1(response1.data.ResultSet);
        setData2(response2.data.ResultSet);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  //Start Tab panel
  const tabRef = React.useRef(null);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    window.scrollTo({ top: document.documentElement.scrollHeight,
      behavior: 'smooth',})
  };
  //End Tab Pannel

  return (
    <Box sx={{ flexGrow: 1, marginTop: 10, width: "100%" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3} md={3}>
          <Paper style={{ padding: 8 }}>
            <Typography
              padding={{ md: 1, sm: 0, xs: 0 }}
              sx={{
                fontSize: 15,
                fontFamily: "segoe ui",
                color: "grey",
                fontWeight: "bold",
              }}
            >
              Profile Details
            </Typography>
            <ImageList
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ImageListItem sx={{ textAlign: "center" }}>
                <img
                  src="/images/usericon.png"
                  alt="sidebarlogo"
                  className="sideBarImg"
                ></img>
              </ImageListItem>
            </ImageList>
            <Typography
              padding={{ md: 1.5, sm: 0, xs: 0 }}
              sx={{
                textAlign: "center",
                fontSize: 14,
                fontFamily: "segoe ui",
              }}
            >
              {data2?.SupplierName}
            </Typography>
            <Divider></Divider>
            <Typography
              padding={{ md: 1, sm: 0, xs: 0 }}
              sx={{
                fontSize: 15,
                fontFamily: "segoe ui",
                color: "grey",
                fontWeight: "bold",
              }}
            >
              About
            </Typography>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              display={"flex"}
              paddingLeft={{ md: 2, sm: 0, xs: 0 }}
              paddingTop={{ md: 0.5, sm: 0, xs: 0 }}
            >
              <FaxIcon />
              <Typography
                sx={{
                  paddingLeft: 2,
                  fontSize: 14,
                  fontFamily: "segoe ui",
                }}
              >
                {data2?.FaxNo}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              display={"flex"}
              paddingLeft={{ md: 2, sm: 0, xs: 0 }}
              paddingTop={{ md: 0.5, sm: 0, xs: 0 }}
            >
              <LanguageIcon />
              <Typography
                sx={{
                  paddingLeft: 2,
                  fontSize: 14,
                  fontFamily: "segoe ui",
                }}
              >
                {data2?.CountryCode}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              display={"flex"}
              paddingLeft={{ md: 2, sm: 0, xs: 0 }}
              paddingTop={{ md: 0.5, sm: 0, xs: 0 }}
            >
              <EmailIcon />
              <Typography
                sx={{
                  paddingLeft: 2,
                  fontSize: 14,
                  fontFamily: "segoe ui",
                }}
              >
                {data2?.EmailAddress}
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        {/* Second Grid Start */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
        <Grid item xs={12} sm={9} md={9}>
          <Paper style={{ padding: 8 }}>
            <Typography
              paddingLeft={{ md: 4, sm: 0, xs: 0 }}
              sx={{
                fontSize: 15,
                fontFamily: "segoe ui",
                // color: "grey",
                padding: 1,
                fontWeight: "bold",
              }}
            >
              Update Profile Informations
            </Typography>
            <Grid container spacing={2} sx={{ padding: 1, color: "black" }}>
              <Grid item xs={12} sm={12} md={10}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="First Name"
                  fullWidth
                  size="small"
                  style={{
                    // backgroundColor: "blue",
                    fontSize: 15,
                    fontFamily: "segoe ui",
                  }}
                  InputProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  InputLabelProps={{}}
                  sx={{}}
                  //required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={10}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="Supplier Category"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="Business Naturey"
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="(PO Address)"
                  fullWidth
                  size="small"
                  // multiline      -----------> For Textarea
                  // rows={2}       -----------> For Textarea Add rows
                  // maxRows={4}    -----------> For Textarea Scale
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="Office Address"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="Operational Address"
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Grid item xs={12} sm={12} md={5.9}>
                  <TextField
                    type="text"
                    variant="outlined"
                    label="Postal Code"
                    fullWidth
                    size="small"
                    sx={{ padding: 0 }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="Country"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="Telephone Number(Other)"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="Fax Number"
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={5}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="Sales Email Address"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="Web Site"
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={5}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="Contact Person"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="Agent"
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Button variant="contained" sx={{ marginBottom: 1 }}>
                  Update Details
                </Button>
              </Grid>
            </Grid>

            {/* -------------------------------------------------------------------------------------------------------------------------------------- */}
            {/* -------------------------------------------------------------------------------------------------------------------------------------- */}
            <Divider></Divider>
            {/* -------------------------------------------------------------------------------------------------------------------------------------- */}
            {/* -------------------------------------------------------------------------------------------------------------------------------------- */}

            <Paper sx={{ padding: 2 }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                paddingLeft={{ md: 2, sm: 0, xs: 0 }}
              >
                <Typography
                  sx={{
                    fontFamily: "segoe ui",
                    fontSize: 15,
                    paddingTop: 0.1,
                    fontWeight: "bold",
                  }}
                >
                  Upload Business Registration Document
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                paddingLeft={{ md: 5, sm: 0, xs: 0 }}
              >
                <input
                  type="file"
                  style={{
                    paddingTop: 10,
                    color: "black",
                    fontSize: 14,
                    width: 250,
                  }}
                ></input>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: 10,
                    margin: 2,
                    "@media (max-width: 595px)": {
                      marginLeft: 0,
                    },
                  }}
                >
                  Upload File
                </Button>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                paddingLeft={{ md: 2, sm: 0, xs: 0 }}
              >
                <Typography
                  sx={{
                    fontFamily: "segoe ui",
                    fontSize: 15,
                    paddingTop: 2,
                    fontWeight: "bold",
                  }}
                >
                  Upload Company Certificate Document
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                paddingLeft={{ md: 5, sm: 0, xs: 0 }}
              >
                <input
                  type="file"
                  style={{
                    paddingTop: 10,
                    color: "black",
                    fontSize: 14,
                    width: 250,
                  }}
                ></input>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: 10,
                    margin: 2,
                    "@media (max-width: 595px)": {
                      marginLeft: 0,
                    },
                  }}
                >
                  Upload File
                </Button>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                paddingLeft={{ md: 2, sm: 0, xs: 0 }}
              >
                <Typography
                  sx={{
                    fontFamily: "segoe ui",
                    fontSize: 15,
                    paddingTop: 2,
                    fontWeight: "bold",
                  }}
                >
                  Upload Form 20!
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                paddingLeft={{ md: 5, sm: 0, xs: 0 }}
              >
                <input
                  type="file"
                  style={{
                    paddingTop: 10,
                    color: "black",
                    fontSize: 14,
                    width: 250,
                  }}
                ></input>
                <Button
                  variant="contained"
                  xs={12}
                  sx={{
                    fontSize: 10,
                    margin: 2,
                    "@media (max-width: 595px)": {
                      marginLeft: 0,
                    },
                  }}
                >
                  Upload File
                </Button>
              </Grid>
            </Paper>
            {/* -------------------------------------------------------------------------------------------------------------------------------------- */}
            {/* -------------------------------------------------------------------------------------------------------------------------------------- */}
            <Divider></Divider>
            {/* -------------------------------------------------------------------------------------------------------------------------------------- */}
            {/* -------------------------------------------------------------------------------------------------------------------------------------- */}
            <Box sx={{ width: "100%", paddingTop: 1 }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  TabIndicatorProps={{
                    sx: {
                      "@media (max-width: 1025px)": {
                        display: "none",
                      },
                    },
                  }}
                  sx={{
                    "& .MuiTabs-flexContainer": {
                      flexWrap: "wrap",
                    },
                  }}
                >
                  <Tab label="Bank Details" {...a11yProps(0)} ref={tabRef}/>
                  <Tab label="Tax Details" {...a11yProps(1)} />
                  <Tab label="Material Details" {...a11yProps(2)} />
                  <Tab label="Attachments" {...a11yProps(3)} />
                </Tabs>
              </Box>
              {/* First Tab Start */}
              <CustomTabPanel value={value} index={0}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sx={{ paddingLeft: 2, paddingTop: 0.1, paddingBottom: 2 }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "segoe ui",
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      Please Upload Your Bank Statement
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <input
                      type="file"
                      style={{ backgroundColor: "", padding: 10 }}
                    ></input>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      sx={{ fontSize: 10, margin: 1 }}
                    >
                      Upload File
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="text"
                      variant="outlined"
                      label="Main Bank"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      type="text"
                      variant="outlined"
                      label="Bank Code"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="text"
                      variant="outlined"
                      label="Branch"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      type="text"
                      variant="outlined"
                      label="Account Number"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="text"
                      variant="outlined"
                      label="Account Type"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained">Update Details</Button>
                  </Grid>
                </Grid>
              </CustomTabPanel>
              {/* Second Tab Start */}
              <CustomTabPanel value={value} index={1}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontFamily: "segoe ui",
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      Please Upload Your VAT Statement!
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontFamily: "segoe ui",
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      Please Upload Your SVAT Statement!
                    </Typography>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <input
                      type="file"
                      style={{ backgroundColor: "", padding: 10 }}
                    ></input>
                  </Grid>
                  <Grid item xs={4}>
                    <input
                      type="file"
                      style={{ backgroundColor: "", padding: 10 }}
                    ></input>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      sx={{ fontSize: 10, margin: 1 }}
                    >
                      Upload File
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      sx={{ fontSize: 10, margin: 1 }}
                    >
                      Upload File
                    </Button>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <TextField
                      type="text"
                      variant="outlined"
                      label="VAT"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      type="text"
                      variant="outlined"
                      label="SVAT"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Divider></Divider>
                  <Grid item xs={12}>
                    <Button variant="contained">Update Details</Button>
                  </Grid>
                </Grid>
              </CustomTabPanel>
              {/* Thired Tab Start */}
              <CustomTabPanel value={value} index={2}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontFamily: "segoe ui",
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      Categories
                    </Typography>
                  </Grid>
                </Grid>
              </CustomTabPanel>
              {/* Fourth Tab Start */}
              <CustomTabPanel value={value} index={3}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Uploaded Attachment
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Attchment Status
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Download
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Doc 1</TableCell>
                        <TableCell>Approved</TableCell>
                        <TableCell>
                          <Button>Download</Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CustomTabPanel>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
