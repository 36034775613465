import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";

const columns = [
  { id: "inquery_number", label: "INQUERY NUMBER", minWidth: 100 },
  { id: "project_number", label: "PROJECT NUMBER", minWidth: 100 },
  { id: "nature_of_ordering", label: "NATURE OF ORDERING", minWidth: 100 },
  { id: "contact_person", label: "CONTACT PERSON", minWidth: 100 },
  { id: "mode_of_dispatch", label: "MODE OF DISPATCH", minWidth: 100 },
  { id: "designation", label: "DESIGNATION", minWidth: 100 },
  { id: "mode_of_shipment", label: "MODE OF SHIPMENT", minWidth: 100 },
  { id: "email", label: "E MAIL", minWidth: 100 },
  { id: "quotation_required", label: "QUOTATION REQUIRED", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 100 },
];

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [data1, setData1] = React.useState([]);
  const [data2, setData2] = React.useState(null);

  React.useEffect(() => {
    Promise.all([
      axios.get(
        "https://esystems.cdl.lk/backend-Test/eSupplier/Supplier/GetInquiryDetails?inquiryId=BS-5001-A03-0742"
      ),
      axios.get(
        "https://esystems.cdl.lk/backend-Test/eSupplier/Supplier/GetSupplierdetailsBySupplier?supCode=M0206"
      ),
    ])
      .then(([response1, response2]) => {
        setData1(response1.data.ResultSet);
        setData2(response2.data.ResultSet);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  function createData(
    inquery_number,
    project_number,
    nature_of_ordering,
    contact_person,
    mode_of_dispatch,
    designation,
    mode_of_shipment,
    email,
    quotation_required,
    action
  ) {
    return {
      inquery_number,
      project_number,
      nature_of_ordering,
      contact_person,
      mode_of_dispatch,
      designation,
      mode_of_shipment,
      email,
      quotation_required,
      action,
    };
  }

  const rows = data1.map((item) => {
    return createData(
      1,
      item.ProjectName,
      item.NatureOfOrdering,
      "sama",
      item.ModeOfDelivery,
      "person1",
      item.ModeOfShipment,
      item.EmailAddress,
      item.QuotationRequiredDate,
      <Button variant="outlined" onClick={handleClickOpen} title="View Details">
        View
      </Button>
    );
  });

  // Extra Expand
  const [expand, setexpand] = React.useState(null);

  const handleClickExapnd = (index) => {
    if (expand === index) {
      setexpand(null);
    } else {
      setexpand(index);
    }
  };
  // End Extra
  //Reset Buuton
  const [openReset, setopenReset] = React.useState(false);

  const handleClickopenReset = () => {
    setopenReset(true);
  };

  const handleCloseReset = () => {
    setopenReset(false);
  };
  //End Reset Button
  return (
    <Paper sx={{ width: "100%", marginTop: 11, boxShadow: "none" }}>
      <TableContainer sx={{ maxHeight: 780 }}>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={{
                    backgroundColor: "lightgrey",
                    textAlign: "center",
                    fontFamily: "segoe ui",
                    fontSize: 15,
                    fontWeight: "bold",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{ textAlign: "center" }}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ border: "none" }}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>
          <Typography
            variant="h3"
            sx={{ fontFamily: "segoe ui", fontSize: 16, fontWeight: "bold" }}
          >
            INQUERY SUBMISSION
          </Typography>{" "}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 4,
              top: 4,
              boxShadow: 0,
              bgcolor: "white",
              color: "black",
              "&:hover": {
                bgcolor: "grey",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogTitle id="scroll-dialog-title">
          <Typography sx={{ fontFamily: "segoe ui", fontSize: 15 }}>
            Please quote your best FOB and Air/Sea Freight charges separately
            along with the availability (Delivery Time) for the supply of items
            mentioned below. Quote our reference purchase enquiry number
            RM-1210-L22-3302 <small>{data2?.SupplierName}</small> in all your
            communications . Contact the relevant officer in the Material
            Procurement Division, for further clarifications.
          </Typography>
        </DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          sx={{ marginTop: 0, padding: 0 }}
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      backgroundColor: "lightgrey",
                      fontFamily: "segoe ui",
                      fontSize: 15,
                      fontWeight: "bold",
                    }}
                  >
                    NO
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      backgroundColor: "lightgrey",
                      fontFamily: "segoe ui",
                      fontSize: 15,
                      fontWeight: "bold",
                    }}
                  >
                    ITEM DESCRIPTION / SPECIFICATION
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      backgroundColor: "lightgrey",
                      fontFamily: "segoe ui",
                      fontSize: 15,
                      fontWeight: "bold",
                    }}
                  >
                    UNIT
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      backgroundColor: "lightgrey",
                      fontFamily: "segoe ui",
                      fontSize: 15,
                      fontWeight: "bold",
                    }}
                  >
                    QUANTITY
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      backgroundColor: "lightgrey",
                      fontFamily: "segoe ui",
                      fontSize: 15,
                      fontWeight: "bold",
                    }}
                  >
                    PRICE
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      backgroundColor: "lightgrey",
                      fontFamily: "segoe ui",
                      fontSize: 15,
                      fontWeight: "bold",
                    }}
                  >
                    REMARKS
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data1.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          fontFamily: "segoe ui",
                          fontSize: 14,
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          fontFamily: "segoe ui",
                          fontSize: 14,
                        }}
                      >
                        <List
                          sx={{ width: "100%", bgcolor: "background.paper" }}
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                        >
                          <ListItemButton
                            onClick={() => handleClickExapnd(index)}
                          >
                            <ListItemText aria-disabled>
                              {item.MaterialDescription}
                              <br></br>
                              {item.Specification}
                            </ListItemText>
                            {expand === index ? (
                              <RemoveIcon title="Contract" />
                            ) : (
                              <AddIcon title="Expand" />
                            )}
                          </ListItemButton>
                          <Collapse in={expand === index} timeout="auto">
                            <List component="div" disablePadding>
                              <ListItemButton sx={{ pl: 4 }}>
                                <ListItemText>
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    sx={{ width: "100%" }}
                                    placeholder="Add Your comment"
                                  />
                                </ListItemText>
                              </ListItemButton>
                            </List>
                          </Collapse>
                        </List>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          fontFamily: "segoe ui",
                          fontSize: 14,
                        }}
                      >
                        {item.Um}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          fontFamily: "segoe ui",
                          fontSize: 14,
                        }}
                      >
                        {item.Quantity}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          fontFamily: "segoe ui",
                          fontSize: 16,
                        }}
                      >
                        <TextField id="outlined-basic" variant="outlined" />
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          fontFamily: "segoe ui",
                          fontSize: 14,
                        }}
                      >
                        <TextField id="outlined-basic" variant="outlined" />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClickopenReset}>
            Reset
          </Button>
          <Button variant="contained">Submit</Button>
        </DialogActions>
      </Dialog>
      {/* Reset Button Dialog Start */}
      <Dialog
        fullScreen={fullScreen}
        open={openReset}
        onClose={handleCloseReset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Reset Form?"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              paddingLeft: 10,
              paddingRight: 10,
              textAlign: "left",
            }}
          >
            Are you sure to reset Data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReset} variant="outlined">
            Reset
          </Button>
          <Button onClick={handleCloseReset} variant="contained" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* Reset Button Dialog End */}
    </Paper>
  );
}
