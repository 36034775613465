import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LogInPage from "./pages/logInPage"
import HomePage from "./pages/homePage"
import Test from "./pages/tableTest" 
import History from "./pages/history"
import PendingInquery from "./pages/pendingInqueries"
import SubmittedInquery from "./pages/submittedInquries"
import Profile from "./pages/profile"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LogInPage/>}></Route>
        <Route path="/home" element={<HomePage/>}></Route>
        <Route path="/history" element={<History/>}></Route>
        <Route path="/pendinginquery" element={<PendingInquery/>}></Route>
        <Route path="/submittedinquery" element={<SubmittedInquery/>}></Route>
        <Route path="/profile" element={<Profile/>}></Route>
        <Route path="/test" element={<Test/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
