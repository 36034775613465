import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Button, ImageList, ImageListItem, ListItem } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
// import HistoryIcon from "@mui/icons-material/History";
import PublishIcon from "@mui/icons-material/Publish";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import PendingIcon from "@mui/icons-material/Pending";
import axios from "axios";
import FaxIcon from "@mui/icons-material/Fax";
import FlagIcon from "@mui/icons-material/Flag";
import EmailIcon from "@mui/icons-material/Email";
import "../resources/css/homePage.css";
import TbData from "./tbData";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,

    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // USer Profile Content Start
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // USer Profile Content End

  // render axios start
  const [data1, setData1] = React.useState([]);
  const [data2, setData2] = React.useState(null);

  React.useEffect(() => {
    Promise.all([
      axios.get(
        "https://esystems.cdl.lk/backend-Test/eSupplier/Supplier/GetInquiryDetails?inquiryId=BS-5001-A03-0742"
      ),
      axios.get(
        "https://esystems.cdl.lk/backend-Test/eSupplier/Supplier/GetSupplierdetailsBySupplier?supCode=M0206"
      ),
    ])
      .then(([response1, response2]) => {
        setData1(response1.data.ResultSet);
        setData2(response2.data.ResultSet);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  //render axios end
  // console.log(data2)

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: "#eeeeee",
          boxShadow: "none"
        }}
      >
        <Toolbar>
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon
              sx={{
                fontSize: 34,
              }}
            />
          </IconButton>
          {/*  */}
          <ImageList>
            <ImageListItem sx={{ width: "160px", alignItems: "center" }}>
              <img
                src="/images/mainlogo.png"
                alt="dockYardLogo"
                className="sideBarImg"
              ></img>
            </ImageListItem>
          </ImageList>
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            sx={{
              fontFamily: "segoe ui",
              color: "black",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            PURCHASING ENQUIRY<br></br>(IMPORT)
          </Typography>
          <Box sx={{ flexGrow: 1.5 }} />

          {/* User Profile Start */}
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2, backgroundColor: "lightblue" }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                sx={{
                  width: 45,
                  height: 45,
                  color: "lightgrey",
                  bgcolor: "#1a237e",
                }}
              >
                {/* {data2?.SupplierName.charAt(0)} */}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open1}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={handleClose}>
              <Avatar />
              {data2?.SupplierName}
            </MenuItem>
            <MenuItem onClick={handleClose}>{data2?.ContactPerson}</MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <FaxIcon fontSize="small" />
              </ListItemIcon>
              {data2?.FaxNo}
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <FlagIcon fontSize="small" />
              </ListItemIcon>
              {data2?.CountryCode}
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <EmailIcon fontSize="small" />
              </ListItemIcon>
              {data2?.EmailAddress}
              test@gmail.com
            </MenuItem>
          </Menu>
          {/* User Profile END */}
        </Toolbar>
      </AppBar>

      {/* Drawer Start */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        //Below PaperProps use for change sidebar color
        PaperProps={{
          sx: {
            backgroundColor: "#222E3C",
          },
        }}
        //End Side Bar solor

        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <ImageList>
            <ImageListItem
              sx={{ width: "160px", alignItems: "center", marginLeft: 1 }}
            >
              <img
                src="/images/navLogoNew.png"
                alt="sidebarlogo"
                className="sideBarImg"
              ></img>
            </ImageListItem>
          </ImageList>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon
                sx={{
                  color: "white",
                  fontSize: "35px",
                  "&:hover": { backgroundColor: "darkgrey", color: "black" },
                }}
              />
            ) : (
              <ChevronRightIcon title="Drawer Open"/>
            )}
          </IconButton>
        </DrawerHeader>
        <Typography
          sx={{
            marginLeft: 2,
            fontFamily: "segoe ui",
            color: "white",
            marginTop: "10px",
            fontSize: "14px",
          }}
        >
          Supplier Management
        </Typography>

        <List
          sx={{
            marginTop: "18px",
            color: "rgb(135, 155, 155)",
          }}
        >
          <ListItem
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <ListItemButton
              sx={{
                paddingTop: 0,
                paddingBottom: 0,
                borderRadius: 1,
                cursor: "default",
              }}
            >
              {/* <ListItemIcon>
                <QuestionMarkIcon
                  sx={{
                    fontSize: 18,
                    marginLeft: 0,
                    color: "rgb(135, 155, 155)",
                  }}
                />
              </ListItemIcon> */}
              <ListItemText
                primary="Inquiries"
                primaryTypographyProps={{
                  fontSize: 16,
                  fontFamily: "segoe ui",
                  margin: 0,
                }}
                sx={{ padding: 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            as={Link}
            to={"/pendinginquery"}
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
              textDecoration: "none",
              color: "inherit",
              underline: "none",
            }}
          >
            <ListItemButton
              sx={{
                paddingTop: 0,
                paddingBottom: 0,
                borderRadius: 1,
                "&:hover": { backgroundColor: "#4b4a4a", color: "black" },
              }}
            >
              <ListItemIcon>
                <PendingIcon
                  sx={{
                    fontSize: 16,
                    marginLeft: 2,
                    color: "rgb(135, 155, 155)",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Pending Inquiries"
                primaryTypographyProps={{
                  fontSize: 13,
                  fontFamily: "segoe ui",
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            as={Link}
            to={"/submittedinquery"}
            sx={{
              paddingTop: 1,
              paddingBottom: 0,
              textDecoration: "none",
              color: "inherit",
              underline: "none",
            }}
          >
            <ListItemButton
              sx={{
                paddingTop: 0,
                paddingBottom: 0,
                borderRadius: 1,
                "&:hover": { backgroundColor: "#4b4a4a", color: "black" },
              }}
            >
              <ListItemIcon>
                <PublishIcon
                  sx={{
                    fontSize: 16,
                    marginLeft: 2,
                    color: "rgb(135, 155, 155)",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Submitted Inquiries"
                primaryTypographyProps={{
                  fontSize: 13,
                  fontFamily: "segoe ui",
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            as={Link}
            to={"/history"}
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
              textDecoration: "none",
              color: "inherit",
              underline: "none",
            }}
          >
            <ListItemButton
              sx={{
                paddingTop: 0,
                paddingBottom: 0,
                borderRadius: 1,
                "&:hover": { backgroundColor: "#4b4a4a", color: "black" },
              }}
            >
              {/* <ListItemIcon>
                <HistoryIcon
                  sx={{
                    fontSize: 16,
                    marginLeft: 2,
                    color: "rgb(135, 155, 155)",
                  }}
                />
              </ListItemIcon> */}
              <ListItemText
                primary="History"
                primaryTypographyProps={{
                  fontSize: 16,
                  fontFamily: "segoe ui",
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
        <Button
          variant="outlined"
          sx={{
            bottom: 40,
            position: "absolute",
            marginLeft: 8.5,
            marginRight: 8.5,
          }}
        >
          Logout
        </Button>
      </Drawer>

      {/* Main content */}
      <Main open={open} sx={{ overflowX: "auto", padding: 1 }}>
        <TbData />
      </Main>
    </Box>
  );
}
